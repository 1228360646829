<template>
  <v-container fluid>

    <TrainerTopTabs
        :add-trainer="true"
        :venue-service-ids="venueServiceIds"
        :venue-services="venueServices"
        @addTrainer="addTrainer"
        @changeVenueService="changeVenueService"
        @update:venueServiceIds="(value)=>venueServiceIds=value"
    />
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>


    <div class="row row--dense">
      <div class="col-12">
       <span class="d-flex text-center align-center gap-x-2 " style="height: 50px ; justify-content: flex-end">
            <span class="">Active Trainers</span>: <v-switch v-model="trainerStatus" false-value="inactive"
                                                             true-value="active"
                                                             @change="changeVenueService"></v-switch>
          </span>
      </div>
    </div>


    <v-row class="mt-4">
      <v-col v-for="(trainer, i) in trainerList" :key="i" lg="4" md="6" sm="12" xl="3">
        <trainer-widget
            v-bind="trainer"
            @delete="confirmDelete"
            @edit="trainerDetails"
        />
      </v-col>
    </v-row>


    <div class="row text-center">
      <div v-if="trainerList.length == 0" class="col-12 text-center">
        <h2> No {{ trainerStatus }} trainer present</h2>
      </div>
      <div class="col-12">
        <v-pagination
            v-if="totalPages"
            v-model="page"
            :length="totalPages"
            class="new-pagination"

        ></v-pagination>
      </div>
    </div>


    <TrainerAddEditDialogue
        v-if="addTrainerDialog"
        :addTrainerDialog="addTrainerDialog"
        :editFlag="editFlag"
        :trainer="trainer"
        :venueServices="venueServices"
        @cancelPersonalTraining="cancelPersonalTraining"
    />

    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirmActions"
    ></confirm-model>
    <color-code
        :productType="'trainer'"
        :viewColorCode="programLevelDialog"
        @close="programLevelDialog = false"
    ></color-code>
    <training-packages
        :trainingPackageDialog="trainingPackageDialog"
        @close="trainingPackageDialog = false"
    ></training-packages>
    <customer-form
        :customerFormDialoge="customerFormDialoge"
        @close="customerFormDialoge = false"
        @complete="customerFormDialoge = false"
    ></customer-form>
  </v-container>
</template>

<script>
import TrainerWidget from "@/components/Trainer/TrainerWidget";
import TrainingPackages from "./TraniningPackages";
import trainer from "@/mixins/trainer";
import ColorCode from "@/components/Settings/ColorCode";
import CustomerForm from "./CustomerForm";
import TrainerAddEditDialogue from "@/views/Trainers/TrainerAddEditDialogue.vue";
import TrainerTopTabs from "@/views/Trainers/TrainerTabs/TrainerTopTabs.vue";


export default {
  components: {
    TrainerTopTabs,
    TrainerAddEditDialogue,

    TrainerWidget,
    ColorCode,
    TrainingPackages,
    CustomerForm,
  },
  mixins: [trainer],
  data() {
    return {
      percentageRules: [
        (value) => {
          if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
            return "Invalid percentage format";
          }
          return true;
        },
      ],
      // percentageRules:
      //   [
      //     (value) => value >= 0 || "Minimum 0",
      //     (value) => value <= 100 || "Maximum 100",

      // ],
      trainerStatus: 'active',
      venueServiceIds: [],
      addTrainerDialog: false,
      customerFormDialoge: false,
      trainer: {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        commission_percentage: null,
        description: null,
        profile_url: null,
        training_services: [
          {
            venue_service_id: null,
            training_packages: [
              {
                name: null,
                session: null,
                price: null,
                package_type: null,
                people: null,
              },
            ],
          },
        ],
        status_id: 1,
        deleted_packages: [],
        deleted_services: [],
      },
      trainerList: [],
      btnShow: false,
      valid: true,
      page: 1,
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      packageTypes: [
        {type: "I", name: "Individual"},
        {type: "C", name: "Couple"},
        {type: "G", name: "Group"},
      ],
      roles: [],
      programLevelDialog: false,
      trainingPackageDialog: false,
      editFlag: false,
      trainerDependencies: [],
      params: { venue_service_ids: [], product_ids: [], product_type_ids: [5] },
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices").then(()=> {
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
        this.loadTrainers()
      });
    }else{
      this.venueServiceIds = this.venueServices.map(
          (item) => item.venue_service_id
      );
      this.loadTrainers()
    }

    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }

    this.getVenueRolesList();
    if (!this.$store.getters.configurationStatus("Trainer")) {
      this.$store.dispatch("loadConfigurations", "Trainer");
    }


    setTimeout(() => {
      this.btnShow = true;
      // this.loadTrainers()
    }, 50);


  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data.filter(
          (service) => service.name != "POS"
      );
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
    venues() {
      let currentVenueCode = this.$store.getters.venueInfo.code;
      return this.$store.getters.userVenues.filter(
          (item) => item.code != currentVenueCode
      );
    },
    isEnableDctErp() {
      if (
          this.$store.getters.userVenues &&
          this.$store.getters.userVenues.length > 0
      ) {
        return this.$store.getters.userVenues[0].enable_dct_erp;
      }
      return 0;
    },
  },
  watch: {
    page() {
      this.loadTrainers();
    },
    venueServices(venuS, old) {
      if (!old.length && venuS.length) {
        this.venueServiceIds = this.venueServices.map(
            (item) => item.venue_service_id
        );
      }
    }

  },
  methods: {
    getVenueRolesList() {
      this.$http
          .get("venues/roles")
          .then((response) => {
            if (response.status == 200) this.roles = response.data.data;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    loadTrainers() {
      let url = `&status=${this.trainerStatus}&per_page=12`;
      this.showLoader("Loading Trainers");

      if (this.venueServiceIds.length > 0) {
        this.venueServiceIds.map((service, index) => {
          url += `&venue_service_id[${index}]=${service}`;
        });
      }
      this.$http
          .get(`venues/trainers?page=${this.page}${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.trainerList = response.data.data;
              this.totalPages = response.data.total_pages;
              console.log(this.venueServiceIds);
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    addTrainer() {
      this.trainer = {
        is_public: 1,
        first_name: null,
        last_name: null,
        designation: null,
        email: null,
        mobile: null,
        image: null,
        username: null,
        password: null,
        password_confirm: null,
        hidePassword2: false,
        hidePassword1: false,
        venue_service_ids: [],
        trainer_ids: [],
        venue_ids: [],
        status_id: 1,
        deleted_packages: [],
        deleted_services: [],
        training_services: [],
      };
      this.addTrainerDialog = true;
      this.editFlag = false;
      setTimeout(() => {
        // this.$refs["image_upload"].cancel();
        // this.$refs.form.resetValidation();
      });
    },
    cancelPersonalTraining(reload) {
      // this.$refs.form.resetValidation();
      this.addTrainerDialog = false;
      if (reload) {
        this.loadTrainers();
      }
    },
    trainerVenueServices() {
      if (
          !this.trainer.venue_service_ids ||
          this.trainer.venue_service_ids.length == 0
      )
        return [];
      let services = this.venueServices.filter((item) =>
          this.trainer.venue_service_ids.includes(item.venue_service_id)
      );
      return services;
    },
    getMinBookingTimes(index) {
      let duration = [];
      let venueServiceId = this.trainer.training_services[index]
          .venue_service_id;
      const venueService = this.venueServices.find(
          (item) => item.venue_service_id == venueServiceId
      );
      const timeIncrement = venueService ? venueService.time_increment : null;
      if (timeIncrement) {
        let hour = 0;
        let index = 0;
        while (hour < 10) {
          index = index + 1;
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min =
              time / 60 - hour > 0 ? Math.round((time / 60 - hour) * 60) : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${min} Min` : "";
          duration.push({value: time, text: text});
        }
      }
      return duration;
    },

    calculateTaxVariation(amount, training_service, training_package, type) {
      let taxTypeId = training_service.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      training_package.price = priceData.price;
      training_package.pre_tax_price = priceData.price.toFixed(2);
      training_package.total_price = priceData.total_price;
      this.$forceUpdate();
    },

    taxChange(training_service) {
      training_service.training_packages.forEach((training_package) => {
        if (training_package.price) {
          this.calculateTaxVariation(
              training_package.price,
              training_service,
              training_package,
              "pre"
          );
        } else if (training_package.total_price) {
          this.calculateTaxVariation(
              training_package.total_price,
              training_service,
              training_package,
              "post"
          );
        }
      });
    },
    getTrainerDependencies() {
      let url = `venues/trainers/short?1=1&`;
      this.showLoader("Loading Trainers");
      if (this.trainer.venue_service_ids.length > 0) {
        this.trainer.venue_service_ids.map((service, index) => {
          url += `&venue_service_ids[${index}]=${service}`;
        });
      }
      this.$http
          .get(`${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.trainerDependencies = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    visibleTrainers() {
      if (this.trainer.id) {
        return this.trainerDependencies.filter(
            (item) => item.id != this.trainer.id
        );
      }
      return this.trainerDependencies;
    },
    setCardData(data) {
      if (!data.customer_id) {
        this.$set(this.trainer, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.trainer, "name", data.first_name);
      }

      if (!data.customer_id && this.trainer.name != data.name) {
        this.$set(this.trainer, "mobile", null);
        this.trainer.search = null;
        this.trainer.nameSearch = null;
        this.$set(this.trainer, "is_public", 1);
        this.$set(this.trainer, "email", null);
        this.$set(this.trainer, "gender", null);
        this.$set(this.trainer, "name", null);
        this.$set(this.trainer, "customer_id", null);
        this.$set(this.trainer, "first_name", null);
        this.$set(this.trainer, "image_path", null);
        this.$set(this.trainer, "dob", null);
        this.$set(this.trainer, "country_id", null);
        this.$set(this.trainer, "last_name", null);
        this.$set(this.trainer, "opt_marketing", false);
        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.trainer, "mobile", data.mobile);
      }
      if (data.email) this.$set(this.trainer, "email", data.email);
      if (data.country_id) {
        this.$set(this.trainer, "country_id", data.country_id);
      } else {
        this.$set(this.trainer, "country_id", null);
      }
      if (data.id_proof_type_id) {
        this.$set(this.trainer, "id_proof_type_id", data.id_proof_type_id);
      }

      if (data.id_proof_number) {
        this.$set(this.trainer, "id_proof_number", data.id_proof_number);
      }

      if (data.gender) {
        this.$set(this.trainer, "gender", data.gender);
      } else {
        this.$set(this.trainer, "gender", null);
      }
      if (data.dob) {
        this.$set(this.trainer, "dob", data.dob);
      } else {
        this.$set(this.trainer, "dob", null);
      }

      if (data.image) {
        this.$set(this.trainer, "image", data.image);
      }

      if (data.name) this.$set(this.trainer, "name", data.name);
      if (data.last_name) {
        this.$set(this.trainer, "last_name", data.last_name);
      } else {
        this.$set(this.trainer, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.trainer, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.trainer, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.trainer, "image_path", data.image_path);
      } else {
        this.$set(this.trainer, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.trainer, "opt_marketing", true);
        } else {
          this.$set(this.trainer, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },
    changeVenueService() {
      if (this.page != 1) {
        this.page = 1;
      } else {
        this.loadTrainers();
      }
    }
  },
};
</script>

<style scoped>
.ptBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.personal-trainer_ic {
  background-image: url("../../assets/images/nav_icon/personal-trainer.png");
  background-position: center center;
  padding: 10px;
  background-size: contain;
}

.active_qp_tab {
  border-bottom: 2px solid black;
  border-radius: 0px;
}

.trainer_stats {
  line-height: 8px;
}

.trainers_row > [class*="col-md-"]:nth-child(3n+2) {
  padding-right: 15px !important;
  padding-left: 15px !important;
}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}

.light-blue-text {
  color: rgba(17, 42, 70, 0.8) !important;
}
</style>
