var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","lg":"12","xl":"12"}},[_c('div',{staticClass:"d-flex justify-space-between "},[_c('div',{staticClass:"d-flex bordered qp-tab-nav"},[_c('div',{staticClass:"nv_item"},[_c('v-autocomplete',{staticClass:"q-autocomplete shadow-0 nv_item_select q-tab-autocomplete",staticStyle:{"width":"160px"},attrs:{"disabled":_vm.venueServices.length === 0,"height":46,"items":_vm.venueServices,"background-color":"rgba(79, 174, 175, 0.1)","color":"#4FAEAF","dense":"","hide-details":"","item-color":"#4FAEAF","item-text":"name","item-value":"venue_service_id","multiple":"","outlined":"","placeholder":"Services"},on:{"change":function($event){return _vm.$emit('changeVenueService')}},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleSelect}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.venueServiceIds.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(_vm._s(_vm.getIcon())+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("All")])],1)],1)]},proxy:true},(_vm.venueServiceIds.length == _vm.venueServices.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis text-neon"},[_vm._v("All Services")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index == 0)?_c('span',{staticClass:"text-elepsis text-neon"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index == 2)?_c('span',{staticClass:"text-elepsis text-neon"},[_vm._v(", ...")]):_vm._e()]}}],null,true),model:{value:(_vm.filterVenueServiceIds),callback:function ($$v) {_vm.filterVenueServiceIds=$$v},expression:"filterVenueServiceIds"}})],1),_c('div',{staticClass:"d-flex align-center p-2 tabs"},[_vm._l((_vm.tabs),function(tab,index){return [(tab.permission)?_c('div',{key:tab.name,staticClass:"nv_item d-flex pointer",class:{'tab':index < _vm.tabs.length-1},on:{"click":function($event){return _vm.gotoPage(tab.path[0])}}},[_c('SvgIcon',{staticClass:"text-thin-gray",class:{
                  'qp-tab-nav-is-active':tab.path.includes(_vm.$route.path),
                  'qp-tab-nav':!tab.path.includes(_vm.$route.path)
                },attrs:{"text":tab.name},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c(tab.icon,{tag:"component",attrs:{"component":tab.icon}})]},proxy:true}],null,true)})],1):_vm._e()]})],2)]),(_vm.addTrainer)?_c('div',{staticClass:"d-flex gap-x-2"},[(_vm.checkWritePermission(_vm.$modules.trainers.dashboard.slug))?_c('v-btn',{staticClass:"white--text blue-color ml-1",attrs:{"color":" darken-1","height":"48","text":""},on:{"click":function($event){return _vm.$emit('addTrainer')}}},[_c('AddIcon'),_c('span',{staticClass:"ml-1"},[_vm._v("Add Trainer")])],1):_vm._e()],1):_vm._e()])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }