<template>
  <v-dialog v-model="customerFormDialoge" scrollable persistent width="750px">
    <v-form ref="form">
      <v-card>
        <v-card-text>
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon
                  class="text-2xl font-semibold"
                  text="Add Student"
                  style="color: black"
                >
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <label for="">Package*</label>
                  <v-select
                    :items="packages"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v.id || 'Package is required']"
                    validate-on-blur
                    return-object
                    v-model="packageSelected"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    :disabled="editFlag"
                    @change="packageChange"
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    dense
                  >
                    <template slot="item" slot-scope="data">
                      <template v-if="!(typeof data.item == 'object')">
                        <v-list-item-content style="text-align: center">
                          {{ data.item }}
                        </v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ data.item.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              data.item.package_type == "I"
                                ? "Individual"
                                : data.item.package_type == "C"
                                ? "Couple"
                                : "Group"
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-select>
                </div>
                <div class="col-md-6 col-sm-6">
                  <label for="">Trainers*</label>
                  <v-select
                    v-model="registerForm.trainer_ids"
                    :rules="[(v) => !!v || 'Trainer(s) is required']"
                    validate-on-blur
                    :items="trainers"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    multiple
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    dense
                    @change="addTrainers"
                  ></v-select>
                </div>

                <div class="col-md-6 col-sm-6">
                  <label for="">Level</label>
                  <v-select
                    v-model="registerForm.level_id"
                    :items="levels"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    dense
                  ></v-select>
                </div>

                <div
                  class="col-md-6 col-sm-6"
                  v-if="checkReadPermission($modules.salesTeam.management.slug)"
                >
                  <label for="">Sales Team</label>
                  <v-select
                    v-model="registerForm.sales_team_id"
                    :items="salesTeams"
                    item-text="name"
                    item-value="id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    class="q-autocomplete shadow-0"
                    hide-details="auto"
                    dense
                  ></v-select>
                </div>
              </div>
            </div>
          </v-row>

          <div class="row row--dense mt-1" v-if="documentFields.length">
            <div class="col-md-12">
              <p class="font-semibold text-base text-blue">Documents</p>
              <v-divider />
            </div>

            <template v-if="documentFields.length">
              <v-col
                cols="12"
                sm="6"
                md="6"
                v-for="(documentField, index) in documentFields"
                :key="index"
              >
                <v-file-input
                  required
                  :label="documentField.name"
                  v-model="documentField.file"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon
                  outlined
                  class="q-text-field shadow-0"
                  dense
                  hide-details="auto"
                  :rules="documentUploadRule(documentField)"
                  validate-on-blur
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="cyan"
                          v-if="
                            documentField.document_id && documentField.file_path
                          "
                          @click="openFile(documentField.file_path)"
                          v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on"> mdi-paperclip </v-icon>
                      </template>
                      <span
                        v-if="
                          documentField.document_id && documentField.file_path
                        "
                        >Download uploaded file</span
                      >
                      <span v-else>Upload Document</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:label>
                    <span v-if="!documentField.isEdit">
                      {{ documentField.name }}
                    </span>
                    <span
                      v-if="
                        documentField.isEdit == true &&
                        documentField.file == null &&
                        documentField.original_file_name
                      "
                      class="font-weight-bold"
                    >
                      <span
                        style="width: 70%; display: inline-block"
                        class="text-truncate"
                        >{{ documentField.original_file_name }}</span
                      >
                      <span
                        style="width: 20%; display: inline-block"
                        class="text-truncate"
                        >.{{
                          documentField.original_file_name.split(".")[
                            documentField.original_file_name.split(".").length -
                              1
                          ]
                        }}</span
                      >
                    </span>
                  </template>
                  <template
                    v-slot:append
                    v-if="documentField.uploaded_file_path"
                  >
                    <v-btn
                      @click="openFile(documentField.uploaded_file_path)"
                      class="text-truncate"
                      depressed
                      color="primary"
                      text
                      style="transform: translate(0, -22%)"
                    >
                      Download {{ documentField.name }}
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </template>
          </div>

          <div class="row row--dense mt-2">
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">
                  Customer details
                </div>
                <div class="d-flex align-center gap-x-4">
                  <label for="">Opt in marketing</label>
                  <v-switch
                    style="float: right"
                    v-model="registerForm.opt_marketing"
                  ></v-switch>
                </div>
              </div>
            </div>

            <!-- <v-col md="2" class="mt-4 text-right" v-if="!orderId">
              <card-data-button
                label="Emirates ID"
                @data="
                  (data) => {
                    setCardData(data);
                  }
                "
              ></card-data-button>
            </v-col> -->
          </div>

          <v-divider class="mb-4"></v-divider>
          <v-row dense align="center">
            <v-col md="3">
              <div style="width: 100%; height: 163px">
                <image-uploader
                  @upload="
                    (data) => {
                      registerForm.image = data;
                    }
                  "
                  :height="220"
                  :image_path="registerForm.image_path"
                  defaultImage="user"
                  @remove="
                    (data) => {
                      registerForm.image = null;
                    }
                  "
                  ref="image_upload"
                  message-text=""
                  text="Customer Picture"
                ></image-uploader>
              </div>
            </v-col>

            <div class="col-md-9">
              <div class="row row--dense">
                <v-col cols="12" sm="12" md="12">
                  <label>Mobile Number*</label>
                  <v-mobile-search
                    :selected="registerForm.mobile"
                    v-model="registerForm.search"
                    @updateCustomer="setCustomerData"
                    class-name1="q-autocomplete shadow-0 "
                    class-name2="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                    :dense="true"
                    hide-details="auto"
                    :show-flag="true"
                    :show-label="false"
                    :dial-code-grid-size="3"
                  ></v-mobile-search>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Name*</label>

                  <v-name-search
                    :selected="registerForm.name"
                    :mobile="registerForm.mobile"
                    :email="registerForm.email"
                    v-model="registerForm.namesearch"
                    class-name="q-text-field shadow-0"
                    :dense="true"
                    label=""
                    @updateCustomer="setCustomerData"
                    hide-details="hide"
                  ></v-name-search>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label>Email*</label>
                  <v-text-field
                    :readonly="registerForm.customer_id != null"
                    v-model="registerForm.email"
                    outlined
                    required
                    :rules="emailRule"
                    validate-on-blur
                    class="q-text-field shadow-0"
                    hide-details="auto"
                    dense
                  ></v-text-field>
                </v-col>
              </div>
            </div>

            <v-col md="6" v-if="field.dob.is_visible">
              <label for="">{{
                customerAgeRange ? "Age Group" : "Date Of Birth"
              }}</label>
              <div v-if="customerAgeRange">
                <v-select
                  v-model="registerForm.age_group"
                  :items="ageRanges"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  dense
                  hide-details="auto"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                ></v-select>
              </div>
              <div v-else>
                <DateOfBirthField
                  v-model="registerForm.dob"
                  :dense="true"
                  :outlined="true"
                >
                </DateOfBirthField>
              </div>
            </v-col>

            <v-col cols="12" sm="6" md="6" v-if="field.nationality.is_visible">
              <label>{{
                `Nationality${field.nationality.is_required ? "*" : ""}`
              }}</label>
              <v-autocomplete
                v-model="registerForm.country_id"
                :items="countries"
                item-value="id"
                item-text="name"
                outlined
                class="q-autocomplete shadow-0"
                hide-details="auto"
                dense
                :hint="`Nationality${field.nationality.is_required ? '*' : ''}`"
                :rules="nationalityRule"
                validate-on-blur
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="6" v-if="field.gender.is_visible">
              <label for="">Gender</label>
              <v-select
                v-model="registerForm.gender"
                :items="['Male', 'Female']"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="genderRule"
                validate-on-blur
                class="q-autocomplete shadow-0"
                hide-details="auto"
                dense
              ></v-select>
            </v-col>
            <v-col sm="12" md="6" v-if="field.tag.is_visible">
              <label for="">{{
                `Tags${field.tag.is_required ? "*" : ""}`
              }}</label>
              <v-select
                :items="tags"
                :placeholder="`Tags${field.tag.is_required ? '*' : ''}`"
                outlined
                item-value="id"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                v-model="registerForm.customer_tag"
                :rules="tagRule"
                validate-on-blur
                class="q-autocomplete shadow-0"
                hide-details="auto"
                dense
              ></v-select>
            </v-col>
          </v-row>

          <v-divider class="mb-4 mt-4"></v-divider>
        </v-card-text>
        <div class="col-md-12s">
          <div
            class="d-flex justify-space-between"
            style="background-color: rgba(231, 234, 236, 1); padding: 6px"
          >
            <div class="d-flex align-center text-xl font-bold m-l-4">
              {{
                discount_applied &&
                discounted_price != undefined &&
                packageSelected &&
                packageSelected.total_price
                  ? "Price:  " + currencyCode + " " + discounted_price
                  : packageSelected && packageSelected.total_price
                  ? "Price:  " +
                    currencyCode +
                    " " +
                    packageSelected.total_price
                  : "Price: " + currencyCode + " 0"
              }}

              <span
                class="text-decoration-line-through"
                v-if="
                  discount_applied && packageSelected && packageSelected.price
                "
              >
                {{ currencyCode + packageSelected.price }}</span
              >
            </div>
            <div class="d-flex align-center">
              <v-autocomplete
                v-if="
                  promotions.length > 0 &&
                  !paidFlag &&
                  this.registerForm.card_number == null &&
                  this.registerForm.trainer_ids &&
                  this.registerForm.trainer_ids.length == 1 &&
                  packageSelected &&
                  packageSelected.price != null
                "
                :items="[{ name: 'None', promotion_code: null }, ...promotions]"
                item-text="name"
                item-value="promotion_code"
                v-model="registerForm.promotion_code"
                outlined
                :readonly="editFlag"
                @change="verifyBenefit('promotion')"
                class="q-autocomplete shadow-0 m-r-1"
                hide-details="auto"
                dense
              >
              </v-autocomplete>
            </div>
          </div>
        </div>
        <v-card-actions>
          <card-data-button
            v-if="!orderId"
            label="HID Omnikey"
            class-name="text-blue text-capitalize light-blue-color ml-4"
            @data="
              (data) => {
                setCardData(data);
              }
            "
          ></card-data-button>
          <card-reader-button
            v-if="!orderId"
            label="Samsotech Reader"
            @data="
              (data) => {
                setCardData(data);
              }
            "
            class-name="m-l-2 text-blue text-capitalize light-blue-color"
          ></card-reader-button>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" @click="close" text>Close</v-btn>
          <v-btn
            class="ma-2 white--text blue-color shadow-0"
            :disabled="!packageSelected"
            @click="addOrUpdateTrainerCustomer"
            >{{ editFlag ? "Update" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import customerTraining from "@/mixins/customerTraining";
import bookingFields from "@/mixins/bookingFieldValidation";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";

export default {
  components: { DateOfBirthField, ImageUploader, SvgIcon },
  props: {
    customerFormDialoge: {
      type: Boolean,
      default: false,
    },
    "trainer-id": { type: Number, default: null },
    "order-id": { type: Number, default: null },
  },
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    this.checkPermission = this.checkExportPermission(
      this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Trainer");
      this.$forceUpdate();
    }
  },
  data() {
    return {
      registerForm: { opt_marketing: false, customers: [{}] },
      editFlag: false,
      packageSelected: {},
      data: { products: [] },
      discounted_price: 0,
      discount_applied: false,
      promotion: false,
      paidFlag: false,
      package: [],
      venue_service_id: null,
      product_id: null,
      productType: "Trainer",
      isEmiratesIdCheck: false,
    };
  },
  mixins: [customerTraining, bookingFields],
  watch: {
    customerFormDialoge: {
      immediate: true,
      handler(val) {
        this.discounted_price = 0;
        this.discount_applied = false;
        this.promotion = false;
        this.paidFlag = false;
        this.registerForm.promotion_code = null;
        this.packageSelected = {};
        this.data.products = [];
        this.trainers = [];

        if (val) {
          this.setFieldConfigurations();
          this.setDocumentFields();
          this.getPackages();
          // this.getTrainers();
          if (this.orderId) {
            this.isEmiratesIdCheck = false;
            this.getOrderDetails();
          } else {
            this.registerForm = { opt_marketing: false, customers: [{}] };
            this.packageSelected = {};
            this.editFlag = false;
          }
          setTimeout(() => {
            this.$refs["image_upload"].cancel();
            this.$refs.form.resetValidation();
          });
        }
      },
    },
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setCustomerData(data) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.registerForm, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.registerForm, "name", data.first_name);
      }

      if (
        this.registerForm.customer_id &&
        !data.customer_id &&
        this.registerForm.name != data.name &&
        this.registerForm.mobile != data.mobile
      ) {
        this.$set(this.registerForm, "mobile", null);
        this.registerForm.search = null;
        this.registerForm.nameSearch = null;
        this.$set(this.registerForm, "email", null);
        this.$set(this.registerForm, "gender", null);
        this.$set(this.registerForm, "name", null);
        this.$set(this.registerForm, "customer_id", null);
        this.$set(this.registerForm, "first_name", null);
        this.$set(this.registerForm, "image_path", null);
        this.$set(this.registerForm, "dob", null);
        this.$set(this.registerForm, "country_id", null);
        this.$set(this.registerForm, "last_name", null);
        this.$set(this.registerForm, "opt_marketing", false);
        this.$set(this.registerForm, "customer_tag", null);
        this.$set(this.age_group, "age_group", null);

        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.registerForm, "mobile", data.mobile);
      if (data.email) this.$set(this.registerForm, "email", data.email);
      if (data.country_id) {
        this.$set(this.registerForm, "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "country_id", null);
        }
      }
      if (data.customer_tag) {
        this.$set(this.registerForm, "customer_tag", data.customer_tag);
      } else {
        this.$set(this.registerForm, "customer_tag", null);
      }
      if (data.gender) {
        this.$set(this.registerForm, "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.registerForm, "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "dob", null);
        }
      }
      if (data.age_group) {
        this.$set(this.registerForm, "age_group", data.age_group);
      }
      if (data.name) this.$set(this.registerForm, "name", data.name);
      if (data.last_name) {
        this.$set(this.registerForm, "last_name", data.last_name);
      } else {
        this.$set(this.registerForm, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.registerForm, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.registerForm, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.registerForm, "image_path", data.image_path);
      } else {
        this.$set(this.registerForm, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.registerForm, "opt_marketing", true);
        } else {
          this.$set(this.registerForm, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },
    addOrUpdateTrainerCustomer() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      var formData = new FormData();
      if (this.registerForm.trainer_ids && this.registerForm.trainer_ids.length)
        this.registerForm.trainer_ids.forEach((trainerId, index) => {
          formData.append(`trainer_ids[${index}]`, trainerId);
        });

      formData.append("training_package_id", this.packageSelected.id);
      formData.append(
        "date",
        this.registerForm.date
          ? this.registerForm.date
          : moment().format("YYYY-MM-DD")
      );
      if (this.registerForm.level_id)
        formData.append("level_id", this.registerForm.level_id);
      if (this.registerForm.order_id)
        formData.append("order_id", this.registerForm.order_id);
      if (this.registerForm.promotion_code)
        formData.append("promotion_code", this.registerForm.promotion_code);

      formData.append(`mobile`, this.registerForm.mobile);
      formData.append(`first_name`, this.registerForm.first_name);

      if (this.registerForm.image)
        formData.append(`profile_image`, this.registerForm.image);
      if (this.registerForm.last_name)
        formData.append(`last_name`, this.registerForm.last_name);
      if (this.registerForm.email)
        formData.append(`email`, this.registerForm.email);

      if (this.registerForm.country_id)
        formData.append(`country_id`, this.registerForm.country_id);
      if (this.registerForm.gender)
        formData.append(`gender`, this.registerForm.gender);
      if (this.registerForm.trainer_booking_id)
        formData.append(
          `trainer_booking_id`,
          this.registerForm.trainer_booking_id
        );
      if (this.registerForm.customer_tag) {
        formData.append(`customer_tag`, this.registerForm.customer_tag);
      }
      if (this.registerForm.customer_id)
        formData.append(`customer_id`, this.registerForm.customer_id);

      if (this.registerForm.name) {
        formData.append(`name`, this.registerForm.name);
      }
      if (this.registerForm.age_group && this.customerAgeRange) {
        formData.append(`age_group`, this.registerForm.age_group);
      } else {
        if (this.registerForm.dob)
          formData.append(`dob`, this.registerForm.dob);
      }
      formData.append(`opt_marketing`, this.registerForm.opt_marketing);
      var document = [];
      this.documentFields.forEach((documentField) => {
        if (documentField.file || documentField.isEdit) {
          document.push(documentField);
        }
      });

      document.forEach((element, index) => {
        formData.append(`documents[${index}][name]`, element.name);
        formData.append(`documents[${index}][id]`, element.id);
        if (element.isEdit) {
          formData.append(`documents[${index}][file_path]`, element.file_path);
          formData.append(
            `documents[${index}][document_id]`,
            element.document_id
          );
          formData.append(
            `documents[${index}][original_file_name]`,
            element.original_file_name
          );
        }

        if (element.file) {
          formData.append(`documents[${index}][file]`, element.file);
        }
      });
      if (this.registerForm.sales_team_id)
        formData.append("sales_team_id", this.registerForm.sales_team_id);
      this.showLoader();
      this.$http
        .post(`venues/trainers/customers`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data;
            this.showSuccess(data.message);
            this.hideLoader();

            this.$emit("complete", {
              id: data.data.id,
              status: data.data.status_id == 5 ? "unpaid" : "paid",
            });
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addCustomer() {
      this.registerForm.customers.push({});
    },
    async getOrderDetails() {
      this.showLoader("Loading");
      await this.$http
        .get(`venues/trainers/customers/${this.orderId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.editFlag = true;
            const data = response.data.data;
            if (data.status_id == 4) {
              this.paidFlag = true;
            }
            this.registerForm = {
              name: null,
              namesearch: null,
              mobile: null,
              search: null,
            };
            setTimeout(() => {
              if (data.documents) {
                data.documents.forEach((document) => {
                  this.documentFields.forEach((documentField) => {
                    if (documentField.id == document.felid_id) {
                      documentField.original_file_name =
                        document.original_file_name;
                      documentField.file_path = document.file_path;
                      documentField.document_id = document.id;
                      documentField.isEdit = true;
                    }
                  });
                });
              }

              this.registerForm = data;
              this.registerForm.order_id = this.orderId;
              this.registerForm.trainer_ids = data.customer_trainers.map(
                (item) => item.trainer_id
              );
              this.registerForm.level_id = data.level_id;
              this.registerForm.training_package_id = data.training_package_id;
              this.registerForm.date = data.date;
              if (data.discount != null) {
                this.discounted_price = data.total;
                this.discount_applied = true;
                if (data.discount.promotion != null) {
                  this.registerForm.promotion_code =
                    data.discount.promotion.promotion_code;
                }
              }
              if (this.packages.length) {
                this.packageSelected = this.packages.find(
                  (item) => item.id == data.training_package_id
                );
                if (this.packageSelected) {
                  this.packageChange(this.packageSelected);
                } else if (data.booked_package !== null) {
                  this.packageSelected = data.booked_package;
                  this.packages.push(this.packageSelected);
                  this.packageChange(this.packageSelected);
                } else this.showError("package not found");
              }
            }, 100);
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    verifyBenefit(type) {
      if (this.data.products.length == 0) {
        this.showError("Please add at least one product");
        return;
      }
      let data = {
        products: [],
      };
      if (type == "promotion") {
        data.promotion_code = this.registerForm.promotion_code;
        if (data.promotion_code == null) {
          this.clearBenefit();
          return;
        }
      }
      if (this.registerForm.mobile) {
        data.mobile = this.registerForm.mobile;
      }

      data.products = this.product;

      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.discount) {
              this.discounted_price = data.total;
              this.discount_applied = true;
              this.data.products = data.products;
              this.$forceUpdate();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    clearBenefit() {
      this.discount_applied = false;
      setTimeout(() => {
        this.$forceUpdate();
      });
    },
    packageChange(pkg) {
      this.product_id = pkg.product_id;
      this.venue_service_id = pkg.venue_service_id;
      this.getTrainers();
      this.$store.dispatch("loadPromotions", {
        date: this.date,
        venue_service_id: pkg.venue_service_id,
        trainer_id: this.trainerId,
        product_type: "Trainer",
        product_id: this.product_id,
      });

      this.product = [];
      if (!this.editFlag) {
        this.discounted_price = 0;
        this.discount_applied = false;
        this.promotion = false;
        this.registerForm.promotion_code = false;
      }

      this.product = [
        {
          product_id: pkg.product_id,
          product_type_id: pkg.product_type_id,
          price: pkg.price,
          name: pkg.name,
          tax: pkg.tax_amount,
          quantity: 1,
          trainer_id:
            this.registerForm.trainer_ids != undefined &&
            this.registerForm.trainer_ids.length > 0
              ? this.registerForm.trainer_ids[0]
              : this.trainerId,
        },
      ];
      this.getLevels(pkg);
    },

    addTrainers() {
      this.discounted_price = 0;
      this.discount_applied = false;
      this.promotion = false;
      this.registerForm.promotion_code = false;
      if (this.registerForm.trainer_ids.length == 1) {
        this.$store.dispatch("loadPromotions", {
          date: this.date,
          trainer_id: this.registerForm.trainer_ids[0],
          venue_service_id: this.venue_service_id,
          product_type: "Trainer",
        });
      } else {
        this.discounted_price = 0;
        this.discount_applied = false;
        this.promotion = false;
        this.registerForm.promotion_code = false;
        this.clearBenefit();
      }
    },

    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },
    setCardData(data) {
      this.setCustomerData(data);
      // if (!data.customer_id) {
      //   this.$set(this.registerForm, "customer_id", null);
      // }

      // if (!data.name && data.first_name) {
      //   this.$set(this.registerForm, "name", data.first_name);
      // }

      // if (!data.customer_id && this.registerForm.name != data.name) {
      //   this.$set(this.registerForm, "mobile", null);
      //   this.registerForm.search = null;
      //   this.registerForm.nameSearch = null;
      //   this.$set(this.registerForm, "email", null);
      //   this.$set(this.registerForm, "gender", null);
      //   this.$set(this.registerForm, "name", null);
      //   this.$set(this.registerForm, "customer_id", null);
      //   this.$set(this.registerForm, "first_name", null);
      //   this.$set(this.registerForm, "image_path", null);
      //   this.$set(this.registerForm, "dob", null);
      //   this.$set(this.registerForm, "country_id", null);
      //   this.$set(this.registerForm, "last_name", null);
      //   this.$set(this.registerForm, "opt_marketing", false);
      //   this.$forceUpdate();
      // }

      // if (data.mobile) {
      //   this.isEmiratesIdCheck = true;
      //   this.$set(this.registerForm, "mobile", data.mobile);
      // }
      // if (data.email) this.$set(this.registerForm, "email", data.email);
      // if (data.country_id) {
      //   this.$set(this.registerForm, "country_id", data.country_id);
      // } else {
      //   this.$set(this.registerForm, "country_id", null);
      // }
      // if (data.country_id) {
      //   this.$set(this.registerForm, "id_proof_type_id", data.id_proof_type_id);
      // }

      // if (data.id_proof_number) {
      //   this.$set(this.registerForm, "id_proof_number", data.id_proof_number);
      // }

      // if (data.gender) {
      //   this.$set(this.registerForm, "gender", data.gender);
      // } else {
      //   this.$set(this.registerForm, "gender", null);
      // }
      // if (data.dob) {
      //   this.$set(this.registerForm, "dob", data.dob);
      // } else {
      //   this.$set(this.registerForm, "dob", null);
      // }

      // if (data.image) {
      //   this.$set(this.registerForm, "image", data.image);
      // }

      // if (data.name) this.$set(this.registerForm, "name", data.name);
      // if (data.last_name) {
      //   this.$set(this.registerForm, "last_name", data.last_name);
      // } else {
      //   this.$set(this.registerForm, "last_name", null);
      // }
      // if (data.first_name)
      //   this.$set(this.registerForm, "first_name", data.first_name);
      // if (data.customer_id)
      //   this.$set(this.registerForm, "customer_id", data.customer_id);
      // if (data.image_path) {
      //   this.$set(this.registerForm, "image_path", data.image_path);
      // } else {
      //   this.$set(this.registerForm, "image_path", null);
      // }
      // if (data.opt_marketing) {
      //   if (data.opt_marketing == 1) {
      //     this.$set(this.registerForm, "opt_marketing", true);
      //   } else {
      //     this.$set(this.registerForm, "opt_marketing", false);
      //   }
      // }
      // this.$forceUpdate();
    },
  },
};
</script>

<style>
.action_btn_variant_1 {
  //background-color: rgba(17, 42, 70, 0.05);
}

.mobile_no_field {
  &.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
    > .v-input__control
    > .v-input__slot,
  .v-text-field.v-text-field--enclosed .v-text-field__details {
    padding: 0px !important;
  }
  fieldset {
    border-radius: 4px 0px 0px 4px;
  }
}

@media (max-width: 1745px) {
  /* Add your styles here */
  .mobile_no_field {
    .mobile_country_code {
      margin-left: 0px !important;
    }
  }
}
</style>
