<template>
  <v-dialog :value="viewColorCode" width="800" @input="close" scrollable>
    <v-card>
      <v-card-title class="headline">Level Color Codes</v-card-title>
      <v-card-text class="pa-4">
        <v-card
          rounded
          outlined
          class="pa-4 mt-2"
          v-for="(main, sIndex) in colorCodes"
          :key="sIndex"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="colorCodes.length > 1"
                fab
                dark
                x-small
                absolute
                top
                right
                color="dark-grey"
                style="margin-right: -20px"
                @click="deleteService(sIndex)"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </template>
            Delete service
          </v-tooltip>
          <v-row>
            <v-col md="12">
              <v-select
                outlined
                background-color="#fff"
                light
                :items="venueServices"
                item-text="name"
                item-value="venue_service_id"
                v-model="main.venue_service_id"
                label="Service name"
              ></v-select>
            </v-col>
            <v-col md="12">
              <v-row
                v-for="(code, cIndex) in main.codes"
                :key="`${cIndex}_${sIndex}`"
              >
                <v-col :md="main.codes.length > 1 ? 7 : 8">
                  <v-text-field
                    outlined
                    background-color="#fff"
                    light
                    v-model="code.name"
                    label="Code name"
                  ></v-text-field>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="code.color_code"
                    hide-details
                    outlined
                    background-color="#fff"
                    light
                  >
                    <template v-slot:append>
                      <v-menu
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle(sIndex, cIndex)" v-on="on" />
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="code.color_code"
                              flat
                              :swatches="swatches"
                              show-swatches
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col md="1" v-if="main.codes.length > 1" class="mt-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="del_btn"
                        color="red"
                        @click="deleteCode(sIndex, cIndex)"
                        x-small
                        dark
                        fab
                      >
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                </v-col>
              </v-row>
              <div class="add_btn">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="blue-color"
                      fab
                      x-small
                      dark
                      @click="addColorCode(sIndex)"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add new code
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <div class="add_btn">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="teal-color"
                dark
                small
                @click="addService"
              >
                <v-icon left>mdi-plus</v-icon> Service
              </v-btn>
            </template>
            Add new service
          </v-tooltip>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="blue-color" dark @click="close">Close</v-btn>
        <v-btn class="teal-color" @click="saveColorCodes" dark>Save</v-btn>
      </v-card-actions>
    </v-card>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
export default {
  props: {
    viewColorCode: { type: Boolean, default: false },
    "product-type": { type: String, default: "academy" },
  },
  data() {
    return {
      colorCodes: [
        {
          venue_service_id: null,
          codes: [
            {
              name: null,
              color_code: "#00AAAAFF",
            },
          ],
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      deleted_codes: [],
    };
  },
  watch: {
    viewColorCode: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getColorCodes();
        }
      },
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    swatchStyle(sIndex, cIndex) {
      const { color_code, menu } = this.colorCodes[sIndex].codes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    addColorCode(sIndex) {
      this.colorCodes[sIndex].codes.push({
        name: null,
        color_code: "#00AAAAFF",
      });
    },
    getColorCodes() {
      this.$http
        .get(`venues/general/color-codes/${this.productType}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.colorCodes = [];
              let currentService = {
                venue_service_id: data[0].venue_service_id,
                codes: [],
              };
              const colorCodes = [];
              data.forEach((code) => {
                if (code.venue_service_id != currentService.venue_service_id) {
                  colorCodes.push(currentService);
                  currentService = {};
                  currentService.venue_service_id = code.venue_service_id;
                  currentService.codes = [];
                }
                currentService.codes.push(code);
              });
              if (currentService.codes.length > 0) {
                colorCodes.push(currentService);
              }
              this.colorCodes = colorCodes;
            }
            this.deleted_services = [];
            this.deleted_codes = [];
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    saveColorCodes() {
      let codes = [];
      this.colorCodes.forEach((code) => {
        code.codes.forEach((element) => {
          element.venue_service_id = code.venue_service_id;
          codes.push(element);
        });
      });
      let data = {
        color_codes: codes,
      };
      if (this.deleted_codes.length) {
        data.deleted_codes = this.deleted_codes;
      }
      this.$http
        .post(`venues/general/color-codes/${this.productType}`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Color codes saved");
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addService() {
      this.colorCodes.push({
        venue_service_id: null,
        codes: [
          {
            name: null,
            color_code: "#00AAAAFF",
          },
        ],
      });
    },
    deleteCode(sIndex, cIndex) {
      let pkg = this.colorCodes[sIndex].codes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes[sIndex].codes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this color code?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "color_code",
          data: {
            sIndex: sIndex,
            cIndex: cIndex,
          },
        };
      }
    },
    deleteService(sIndex) {
      this.confirmModel = {
        id: sIndex,
        title: "Do you want to delete this service?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "service",
      };
    },
    confirmActions(data) {
      if (data.type == "color_code") {
        let sIndex = data.data.sIndex;
        let cIndex = data.data.cIndex;

        if (this.colorCodes[sIndex].codes[cIndex].id != null) {
          this.deleted_codes.push(this.colorCodes[sIndex].codes[cIndex].id);
        }
        this.colorCodes[sIndex].codes.splice(cIndex, 1);
        this.$forceUpdate();
      } else if (data.type == "service") {
        let index = data.id;
        let service = this.colorCodes[index];
        if (service.codes)
          service.codes.forEach((code) => {
            if (code.id != null) {
              this.deleted_codes.push(code.id);
            }
          });
        this.colorCodes.splice(index, 1);
      }
      this.confirmModel.id = null;
    },
  },
};
</script>

<style></style>
