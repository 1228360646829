<template>
  <v-card class="shadow trainer_card " :ripple="false" style="border-radius: 8px" @click="trainerCustomers">
    <v-card-text class="relative">
      <v-menu
          content-class="q-menu"
          absolute
          top
          right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="text_capitalize "
              elevation="0"
              style="background-color: transparent; min-width: fit-content !important; height: fit-content !important; padding: 2px !important "
              v-bind="attrs"
              v-on="on"
              absolute
              top
              right
              :ripple="false"

          >
            <DotsIcon/>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              v-if="checkWritePermission($modules.trainers.management.slug)"
              @click="$emit('edit',id,'view',true)"
          >
            <SvgIcon class="font-medium text-sm gap-x-2" text="Edit">
              <template #icon>
                <EditIcon height="16" viewBox="0 0 20 20" width="16"/>
              </template>
            </SvgIcon>
          </v-list-item>
          <v-divider class="mb-2"/>
          <v-list-item v-if="checkDeletePermission($modules.trainers.management.slug)" @click="$emit('delete',{
              id: id,
              status_id: status_id,
              flag: false,
            })">
            <SvgIcon :text="(status_id === 1 ? 'Deactivate' : 'Activate')"
                     class="font-medium text-sm gap-x-2" :class="{'red--text svg-stroke-red':status_id === 1}">
              <template #icon>
                <ActivateIcon/>
              </template>
            </SvgIcon>
          </v-list-item>
        </v-list>
      </v-menu>

      <div class="row row--dense no-gutters">
        <div class="col-md-4 col-lg-4 col-sm-12 trainer_img_container">
          <simple-image :image="image" defaultImage="user" style="border-radius: 6px"></simple-image>
        </div>
        <div class="col-lg-8 col-md-8 col-sm-12 ">
          <div class="trainer-details  ml-3 mt-4 mt-lg-0">
            <p class="font-semibold text-lg black--text text-elepsis trainer_name">{{ first_name }} {{
                last_name || ""
              }}</p>
            <div class=""><p class="text-base black-text trainer_designation">
              {{ designation ? designation.slice(0, 20) : 'N/A' }}</p></div>
            <div class="d-flex gap-x-2 align-center mt-5 stats_col">
              <SMSIcon/>
              <span class="black--text text-base  text-elepsis ">{{ email }}</span>
            </div>
            <div class="d-flex gap-x-2 align-center mt-2 stats_col">
              <CallIcon/>
              <span class="black--text text-base email_trainer">{{ mobile }}</span>
            </div>
          </div>
        </div>
      </div>
      <v-row class="border-bottom pb-2 mt-2" dense>
        <v-col cols="12">
          <div v-if="services" class="tags d-flex gap-x-2 ">
            <v-btn v-for="(service, index) in services.split(',').slice(0,2)" :key="index"
                   class="text-capitalize text-blue font-medium"
                   depressed small
            >{{ service }}
            </v-btn>
            <v-btn v-if="services.split(',').length-2 > 0" depressed small>+{{ services.split(',').length - 2 }}</v-btn>
          </div>
          <div v-else class="tags d-flex gap-x-2 ">
            <v-btn depressed small>N/A</v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="row row--dense mt-4">
        <div class="col-md-6 justify-center text-center">
          <div class="trainer_stats">
            <p class="text-base text-dark-gray">Students</p>
            <p class="text-lg font-medium text-blue  ">{{ Number(students) | numberFormatter }}</p>
          </div>
        </div>
        <div v-if="checkReadPermission($modules.trainers.sales.slug)" class="col-md-6">
          <div class="trainer_stats text-center">
            <p class="text-base text-dark-gray">Sales</p>
            <p class="text-lg font-medium text-blue ">{{ Number(total_sales) | toCurrency }} </p>
          </div>
        </div>

        <div v-if="checkReadPermission($modules.trainers.revenue.slug)" class="col-md-6 col-lg-6 col-sm-12">
          <div class="trainer_stats text-center">
            <p class="text-base text-dark-gray">Revenue</p>
            <p class="text-lg font-medium text-blue ">{{ Number(sales)  | toCurrency }} </p>
          </div>
        </div>
        <div v-if="checkReadPermission($modules.trainers.commission.slug)" class="col-md-6">
          <div class="trainer_stats text-center">
            <p class="text-base text-dark-gray">Commission</p>
            <p class="text-lg font-medium text-blue">{{ Number(commission) | toCurrency }} </p>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import SMSIcon from "@/assets/images/misc/sms.svg";
import CallIcon from "@/assets/images/misc/call.svg";
import store from "@/store";
import SimpleImage from "@/components/Image/SimpleImage.vue";
import EditIcon from "@/assets/images/tables/edit.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import DotsIcon from "@/assets/images/misc/h-options.svg";
import ActivateIcon from "@/assets/images/partners/activate.svg";

export default {
  computed: {
    store() {
      return store
    }
  },
  components: {
    ActivateIcon,
    DotsIcon,
    SvgIcon,
    EditIcon,
    SimpleImage, SMSIcon, CallIcon
  },
  props: {
    id: {type: Number, default: 0},
    first_name: {type: String, default: ""},
    last_name: {type: String, default: ""},
    designation: {type: String, default: ""},
    email: {type: String, default: ""},
    mobile: {type: String, default: ""},
    sales: {type: Number, default: 0},
    total_sales: {type: Number, default: 0},
    commission: {type: Number, default: 0},
    students: {type: Number, default: 0},
    status_id: {type: Number, default: 1},
    image: {
      type: String,
      default: null,
    },
    services: {type: String, default: ""},
  },
  methods: {
    trainerCustomers() {
      // if (this.services ) {
      this.$router.push({
        name: "TrainerCustomers",
        params: {data: btoa(this.id)},
      });
      // } else {
      //   this.showError("Please assign service to trainer to proceed");
      //   this.$emit('edit');
      // }
    },
    getWidth() {
      return this.checkReadPermission(this.$modules.trainers.commission.slug)
          ? 6
          : 4;
    },

  },
};
</script>

<style scoped>
.button-style {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1%;
  padding-right: 2%;
}


.trainer_stats {
  p {
    margin: 0px;
  }
}

.trainer-details {
  p {
    padding: 0;
    margin: 0;
  }
}

.trainer_card {
  border: 1px solid rgba(17, 42, 70, 0);
  cursor: pointer;
}

.trainer_card:hover {
  border: 1px solid rgba(17, 42, 70, 1);
  box-shadow: 0 8px 24px 0 rgba(70, 76, 136, 0.20) !important
}


.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 75%;
  overflow: hidden;
}

.trainer_card {
  .v-card__text {
    line-height: 1.5;
  }
}

@media screen and (min-width: 1264px) and (max-width: 1380px) {
  .stats_col {
    margin-top: 8px !important;
    margin-bottom: 0 !important;
  }

  .v-image {
    aspect-ratio: 1/1.2 !important;
  }
}

@media screen and (min-width: 1381px) and (max-width: 1560px) {
  .stats_col {
    margin-top: 4px !important;
    margin-bottom: 0 !important;
  }

}


@media screen and (min-width: 1904px) and (max-width: 2000px) {
  .stats_col {
    margin-top: 4px !important;
    margin-bottom: 0 !important;
  }
}

@media screen and (min-width: 2001px) and (max-width: 2150px) {
  .stats_col {
    margin-top: 8px !important;
    margin-bottom: 0 !important;
  }
}


</style>
