var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"50%"},model:{value:(_vm.trainingPackageDialog),callback:function ($$v) {_vm.trainingPackageDialog=$$v},expression:"trainingPackageDialog"}},[_c('v-form',{ref:"form"},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Training Packages")]),_c('v-card-text',{staticClass:"form_bg"},[_c('v-container',_vm._l((_vm.trainingServices),function(trainingService,index){return _c('v-card',{key:index,staticClass:"pt-3 pl-2 pr-2 mb-3",style:(_vm.cardStyle),attrs:{"elevation":"0"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.trainingServices.length > 1)?_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-right":"-20px"},attrs:{"fab":"","dark":"","x-small":"","absolute":"","top":"","right":"","color":"dark-grey"},on:{"click":function($event){return _vm.removeTrainingService(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" Delete ")]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.venueServices,"clearable":"","label":"Select Service","item-text":"name","item-value":"venue_service_id","outlined":"","background-color":"#fff","required":"","rules":[function (v) { return !!v || 'Service is required'; }]},model:{value:(trainingService.venue_service_id),callback:function ($$v) {_vm.$set(trainingService, "venue_service_id", $$v)},expression:"trainingService.venue_service_id"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.taxTypes,"clearable":"","label":"Tax type","item-text":"text","item-value":"value","outlined":"","background-color":"#fff","required":"","rules":[function (v) { return !!v || 'Tax type is required'; }]},model:{value:(trainingService.tax_type_id),callback:function ($$v) {_vm.$set(trainingService, "tax_type_id", $$v)},expression:"trainingService.tax_type_id"}})],1)],1),_vm._l((trainingService.training_packages),function(trainerPackage,pIndex){return _c('v-card',{key:(index + "_" + pIndex),staticClass:"mb-6",attrs:{"outlined":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(trainingService.training_packages.length > 1)?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"color":"red","absolute":"","right":"","top":"","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.removeTrainingPackage(index, pIndex)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_vm._v(" Delete ")]),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":"Name*","required":"","rules":[function (v) { return !!v || 'Package name is required'; }],"outlined":"","background-color":"#fff"},model:{value:(trainerPackage.name),callback:function ($$v) {_vm.$set(trainerPackage, "name", $$v)},expression:"trainerPackage.name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"label":"Duration","required":"","hint":"Session duration (default 1 hr)","items":[
                        { text: '15 min', value: 15 },
                        { text: '30 min', value: 30 },
                        { text: '45 min', value: 45 },
                        { text: '1 hr', value: 60 } ],"outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":"#fff"},model:{value:(trainerPackage.duration),callback:function ($$v) {_vm.$set(trainerPackage, "duration", $$v)},expression:"trainerPackage.duration"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":"Sessions*","required":"","rules":[
                        function (v) { return !!v || 'Sessions is required'; },
                        function (v) { return !isNaN(v) || 'Sessions must be Number'; } ],"outlined":"","background-color":"#fff"},model:{value:(trainerPackage.sessions),callback:function ($$v) {_vm.$set(trainerPackage, "sessions", $$v)},expression:"trainerPackage.sessions"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-select',{attrs:{"label":"Type*","required":"","outlined":"","menu-props":{ bottom: true, offsetY: true },"background-color":"#fff","items":_vm.packageTypes,"item-text":"name","item-value":"type","rules":[function (v) { return !!v || 'Type is required'; }]},on:{"change":function($event){return _vm.setPackageTypePeople(index, pIndex)}},model:{value:(trainerPackage.package_type),callback:function ($$v) {_vm.$set(trainerPackage, "package_type", $$v)},expression:"trainerPackage.package_type"}})],1),(trainerPackage.package_type == 'G')?_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":"People*","required":"","rules":[
                        function (v) { return !!v || 'No persons is required'; },
                        function (v) { return !isNaN(v) || 'Price must be Number'; } ],"outlined":"","background-color":"#fff"},model:{value:(trainerPackage.people),callback:function ($$v) {_vm.$set(trainerPackage, "people", $$v)},expression:"trainerPackage.people"}})],1):_vm._e(),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":"Price*","required":"","rules":[
                        function (v) { return !!v || 'Price is required'; },
                        function (v) { return !isNaN(v) || 'Price must be Number'; } ],"prefix":_vm.currencyCode,"outlined":"","background-color":"#fff"},model:{value:(trainerPackage.price),callback:function ($$v) {_vm.$set(trainerPackage, "price", $$v)},expression:"trainerPackage.price"}})],1)],1)],1)],1)}),_c('div',{staticClass:"add_btn pb-4",staticStyle:{"margin-top":"-30px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal","fab":"","x-small":"","dark":""},on:{"click":function($event){return _vm.addPackage(index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_vm._v(" Add ")])],1)],2)}),1),_c('div',{staticClass:"add_btn"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"teal-color","small":"","dark":""},on:{"click":_vm.addService}},'v-btn',attrs,false),on),[_vm._v(" Service "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1)]}}])},[_vm._v(" Add ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ma-2 white--text blue-color",on:{"click":_vm.close}},[_vm._v("Close")]),_c('v-btn',{staticClass:"ma-2 white--text teal-color",on:{"click":function($event){return _vm.addOrEditTrainerServices($event)}}},[_vm._v("Update")])],1)],1)],1),_c('confirm-model',_vm._b({on:{"confirm":_vm.confirmActions,"close":function($event){_vm.confirmModel.id = null}}},'confirm-model',_vm.confirmModel,false))],1)}
var staticRenderFns = []

export { render, staticRenderFns }