<template>
  <v-dialog v-model="addTrainerDialog" scrollable width="823px" >
    <v-form ref="form" v-model="valid">
      <v-card >
        <v-card-text class="border-bottom mb-3">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" :text="`${trainer.id!=null ? 'Update Trainer':'Add Trainer'}`" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="cancelPersonalTraining">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>


          <v-row class="mt-8" dense >
            <div class="col-lg-3 col-md-3"  style="aspect-ratio: 1" >
              <image-uploader
                  @upload="
                      (data) => {
                        trainer.profile_image = data;
                      }
                    "
                  :image_path="trainer.image_path"
                  :height="200"
                  defaultImage="user"
                  @remove="
                      () => {
                        trainer.profile_image = null;
                      }
                    "
                  text="Trainer picture"
                  :show-guide="false"
                  message-text=""
                  ref="image_upload"
              ></image-uploader>
            </div>

            <div class="col-lg-9 col-md-9">
              <div class="row row--dense mt-2">
                <v-col cols="12" sm="6" md="6">
                  <label for="">First Name</label>
                  <v-text-field
                      v-model="trainer.first_name"
                      outlined
                      required
                      :rules="[(v) => !!v || 'First name is required']"
                      validate-on-blur
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Last Name</label>
                  <v-text-field
                      v-model="trainer.last_name"
                      outlined
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                      required
                      :rules="[(v) => !!v || 'Last name is required']"
                      validate-on-blur

                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label for="">Mobile Number</label>
                  <mobile-number-field
                      v-model="trainer.mobile"
                      :dense="true" :outlined="true" :variant="1"
                  />

                </v-col>


                <v-col cols="12" sm="6" md="6">
                  <label for="">Email</label>
                  <v-text-field
                      v-model="trainer.email"
                      outlined
                      class="q-text-field shadow-0"
                      hide-details="auto"
                      dense
                      required
                      validate-on-blur

                      :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                  ></v-text-field>
                </v-col>
              </div>
            </div>
          </v-row>
          <v-row dense class="mt-3">
            <v-col cols="4" sm="4" md="4">
              <label for="">Username</label>
              <v-text-field
                  v-model="trainer.username"
                  outlined
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  autocomplete="off"
                  :name="`username_${Math.random()}`"
                  required
                  :rules="[(v) => !!v || 'Username is required']"
                  validate-on-blur

              ></v-text-field>
            </v-col>


            <v-col v-if="!editFlag" cols="4" md="4">
              <label for="">Password</label>
              <v-text-field
                  :type="trainer.hidePassword1 ? 'text' : 'password'"
                  :append-icon="
                      trainer.hidePassword1 ? 'mdi-eye' : 'mdi-eye-off'
                    "
                  outlined
                  background-color="#fff"
                  v-model="trainer.password"
                  @click:append="
                      trainer.hidePassword1 = !trainer.hidePassword1
                    "
                  required
                  autocomplete="new-password"
                  :name="`password_${Math.random()}`"
                  :rules="[(v) => !!v || 'Password is required']"
                  validate-on-blur

                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              />
            </v-col>
            <v-col v-if="!editFlag" cols="4" md="4">
              <label for="">Confirm Password</label>
              <v-text-field
                  :type="trainer.hidePassword2 ? 'text' : 'password'"
                  :append-icon="
                      trainer.hidePassword2 ? 'mdi-eye' : 'mdi-eye-off'
                    "
                  outlined
                  background-color="#fff"
                  :autocomplete="
                      `trainer-password-confirm-${parseInt(trainer.name)}`
                    "
                  v-model="trainer.password_confirm"
                  @click:append="
                      trainer.hidePassword2 = !trainer.hidePassword2
                    "
                  validate-on-blur
                  required
                  :rules="[
                      (v) => !!v || 'Password is required',
                      (v) =>
                        v == this.trainer.password || 'Password does not match',
                    ]"

                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              />
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <label for="">Designation</label>
              <v-text-field
                  v-model="trainer.designation"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  outlined
                  required
                  :rules="[(v) => !!v || 'Designation is required']"
                  validate-on-blur

              ></v-text-field>
            </v-col>
            <v-col
                md="4"
                v-if="checkWritePermission($modules.trainers.commission.slug)"
            >
              <label for="">Commission Percentage</label>
              <v-text-field
                  v-model="trainer.commission_percentage"
                  :rules="percentageRules"
                  validate-on-blur

                  type="text"
                  required
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  outlined

              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <label for="">Roles</label>
              <v-select
                  v-model="trainer.role_id"
                  :items="roles"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="title"
                  :rules="[(v) => !!v || 'Role is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur

              ></v-select>
            </v-col>
            <v-col md="4">
              <label for="">Trainer Service </label>
              <v-select
                  v-model="trainer.venue_service_ids"
                  :items="venueServices"
                  @change="getTrainerDependencies"
                  item-text="name"
                  multiple
                  item-value="venue_service_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  required
                  :rules="[(v) => v.length > 0 || 'Service is required']"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur

              >
                <template
                    v-if="
                        trainer.venue_service_ids &&
                          venueServices.length ==
                            trainer.venue_service_ids.length
                      "
                    v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Services</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>
            <v-col md="4">
              <label for="">Visible Trainers</label>
              <v-select
                  v-model="trainer.trainer_ids"
                  :items="visibleTrainers()"
                  item-text="name"
                  multiple
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  required
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
              >
                <template
                    v-if="
                        trainer.trainer_ids &&
                          trainerDependencies.length ==
                            trainer.trainer_ids.length
                      "
                    v-slot:selection="{ index }"
                >
                  <span v-if="index == 0">All Trainers</span>
                </template>
                <template v-else v-slot:selection="{ item, index }">
                  <span v-if="index == 0">{{ item.name }}</span>
                  <span v-if="index == 1">, ...</span>
                </template>
              </v-select>
            </v-col>
            <v-col
                cols="12"
                md="4"
                v-if="$store.getters.userVenues.length > 1"
            >
              <label for="">Venues</label>
              <v-select
                  v-model="trainer.venue_ids"
                  :items="venues"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  item-text="name"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  multiple
              ></v-select>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label for="">Trainer Description</label>
              <v-textarea rows="4"
                          v-model="trainer.description"
                          outlined
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          hide-details="auto"
                          dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <label for="">Youtube Promo video URL</label>
              <v-text-field
                  v-model="trainer.profile_url"
                  outlined
                  background-color="#fff"
                  autocomplete="off"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ><v-tooltip slot="append" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="primary" dark>
                    mdi-information
                  </v-icon>
                </template>
                <span
                >Please copy the embed URL for a video and paste
                        here.</span
                >
              </v-tooltip></v-text-field
              >
            </v-col>

          </v-row>


          <v-row dense class="border-bottom">
            <div class="col-md-12 d-flex gap-x-4">
              <label for="" class="d-flex text-center align-center">Enable Online</label>
              <v-switch
                  :false-value="0"
                  :true-value="1"
                  v-model="trainer.is_public"
                  class="d-flex text-center align-center"
              ></v-switch>
            </div>
          </v-row>

          <v-row class="mt-4" dense >
            <div class="col-md-12">
              <p class="font-bold text-lg black-text">Speciality Packages</p>
            </div>
          </v-row>


          <template v-if="trainer.status_id == 1">

            <v-card
                style="border: 1px solid rgba(0, 0, 0, 0.15)"
                class="mt-3 pl-2 pr-2 mb-4 shadow-0"
                v-for="(training_service, ts) in trainer.training_services"
                :key="ts"
                elevation="0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      v-bind="attrs"
                      v-on="on"
                      fab
                      absolute
                      top
                      right
                      style="margin-right: -20px"
                      @click="deleteTrainerService(ts)"
                      x-small
                      class="shadow-0"

                  >

                    <DeleteIcon/>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
              <v-row dense class="mt-2">
                <v-col md="6">
                  <label for="">Service*</label>
                  <v-autocomplete
                      v-model="training_service.venue_service_id"
                      :items="trainerVenueServices()"
                      clearable
                      item-text="name"
                      item-value="venue_service_id"
                      outlined
                      required
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="6">
                  <label for="">Tax Type*</label>
                  <v-autocomplete
                      @change="taxChange(training_service)"
                      v-model="training_service.tax_type_id"
                      :items="taxTypes"
                      item-text="text"
                      item-value="value"
                      outlined
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      required
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card
                  v-for="(training_package,
                    tp) in training_service.training_packages"
                  :key="`${ts}_${tp}`"
                  class="mb-2 mt-5 shadow-0" style="border: 1px solid rgba(0, 0, 0, 0.15) "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        top
                        right
                        absolute
                        @click="warningPackageRemove(ts, tp)"
                        fab
                        x-small
                        class="shadow-0"

                    >
                      <DeleteIcon/>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <div class="pa-4">
                  <v-row dense>
                    <v-col md="4">
                      <label> Name*</label>
                      <v-text-field

                          required
                          v-model="training_package.name"
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <label for="">Duration</label>
                      <v-select

                          required
                          v-model="training_package.duration"
                          hint="Session duration (default 1 hr)"
                          :items="getMinBookingTimes(ts)"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-select>
                    </v-col>
                    <v-col
                        :md="training_package.package_type == 'G' ? 3 : 4"
                    >
                      <label for="">Sessions*</label>
                      <v-text-field
                          required
                          v-model="training_package.sessions"
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        :md="training_package.package_type == 'G' ? 3 : 4"
                    >
                      <label for="">Type*</label>
                      <v-select
                          required
                          v-model="training_package.package_type"
                          outlined
                          :menu-props="{ bottom: true, offsetY: true }"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          :items="packageTypes"
                          @change="setPackagePeople(ts, tp)"
                          item-text="name"
                          item-value="type"
                      ></v-select>
                    </v-col>
                    <v-col
                        :md="3"
                        v-if="training_package.package_type == 'G'"
                    >
                      <label for="">People*</label>
                      <v-text-field

                          required
                          v-model="training_package.people"
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        :md="training_package.package_type == 'G' ? 3 : 4"
                    >
                      <label for="">Price (Pre Tax)*</label>
                      <v-text-field

                          @change="
                              calculateTaxVariation(
                                $event,
                                training_service,
                                training_package,
                                'pre'
                              )
                            "
                          required
                          v-model="training_package.pre_tax_price"
                          outlined
                          :prefix="currencyCode"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        :md="training_package.package_type == 'G' ? 3 : 4"
                    >
                      <label for="">Price (Post Tax)*</label>
                      <v-text-field

                          @change="
                              calculateTaxVariation(
                                $event,
                                training_service,
                                training_package,
                                'post'
                              )
                            "
                          required
                          v-model="training_package.total_price"
                          outlined
                          :prefix="currencyCode"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="isEnableDctErp">
                    <v-col cols="12" sm="6" md="3">
                      <label for="">Project Number*</label>
                      <v-text-field
                          outlined

                          v-model="training_package.project_no"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          placeholder="Project Number"
                          required
                          validate-on-blur

                          :rules="[
                              (v) => !!v || 'Project number is required',
                            ]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label for="">Task Name  *</label>
                      <v-text-field
                          outlined
                          v-model="training_package.task_name"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          label="Task Name *"
                          placeholder="Task Name"
                          required
                          :rules="[(v) => !!v || 'Task name is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label for="">GL Code*</label>
                      <v-text-field
                          outlined
                          v-model="training_package.gl_code"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          validate-on-blur

                          required
                          :rules="[(v) => !!v || 'Gl Code is required']"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                      <label for="">Transaction Type*</label>
                      <v-text-field
                          outlined
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          v-model="training_package.transaction_type"

                          placeholder="Transaction Type"
                          required
                          :rules="[
                              (v) => !!v || 'Transaction Type is required',
                            ]"
                          validate-on-blur

                      ></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-card>

              <div class="col-md-12">
                <span class="text-underline font-semibold text-base" style="cursor: pointer ; color: rgba(79, 174, 175, 1)"   @click="addTrainerPackage(ts)"> + Add New </span>
              </div>


            </v-card>

          </template>

          <v-row class="mb-3" >

            <div class="col-md-12"  v-if="trainer.training_services.length < 3">
              <v-btn
                  class="text-white teal-color shadow-0"
                  text
                  height="40"
                  @click="addTrainerService"
              >
                <AddIcon/>
                <span class="ml-1">Add Service</span>
              </v-btn>

            </div>
          </v-row>

        </v-card-text>

        <v-card-actions >
          <card-data-button
              label="HID Omnikey"
              class-name="m-l-2 text-blue text-capitalize light-blue-color"

              @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
          ></card-data-button>
          <card-reader-button
              class-name="m-l-2 text-blue text-capitalize light-blue-color"
              label="Samsotech Reader"
              @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
          ></card-reader-button>

          <v-spacer></v-spacer>
          <v-btn
              class="ma-2 "
              text
              @click="cancelPersonalTraining"
          >Close</v-btn
          >

          <v-btn
              class="ma-2 white--text blue-color" @click="saveTrainer"
          >{{ trainer.id != null ? "Update" : "Save" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
        v-bind="confirmModel"
        @confirm="confirmActions"
        @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>

</template>
<script>
import MobileNumberField from "@/components/Fields/MobileNumberField.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DeleteIcon from "@/assets/images/misc/delete-bg-icon.svg";
import trainer from "@/mixins/trainer";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  components: {AddIcon, ImageUploader, SvgIcon, MobileNumberField , DeleteIcon},
  mixins: [trainer],
  props:{
    addTrainerDialog:{type:Boolean, default:false},
    editFlag:{type:Boolean, default:false},
    trainer:{type:Object, default:null},
    venueServices:{type:Array, default:null},
  },

  mounted() {
    this.getVenueRolesList();
    if(this.trainer.id){
      this.getTrainerDependencies();
    }
    if (this.$store.getters.getTaxTypes.status == false) {
      this.$store.dispatch("loadTaxTypes");
    }
  },

  computed:{
    venues() {
      let currentVenueCode = this.$store.getters.venueInfo.code;
      return this.$store.getters.userVenues.filter(
          (item) => item.code != currentVenueCode
      );
    },
    isEnableDctErp() {
      if (
          this.$store.getters.userVenues &&
          this.$store.getters.userVenues.length > 0
      ) {
        return this.$store.getters.userVenues[0].enable_dct_erp;
      }
      return 0;
    },

    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  methods:{

    saveTrainer(e){
      if(this.trainer.id){
        this.addOrEditTrainer(e , 'EditCustomerModal')
      }else{
        this.addOrEditTrainer(e , false)
      }
    },
    cancelPersonalTraining() {
      this.$refs.form.resetValidation();
      this.$emit('cancelPersonalTraining')
    },
    getVenueRolesList() {
      this.$http
          .get("venues/roles")
          .then((response) => {
            if (response.status == 200) this.roles = response.data.data;
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    getTrainerDependencies() {
      let url = `venues/trainers/short?1=1&`;
      this.showLoader("Loading Trainers");
      if (this.trainer.venue_service_ids.length > 0) {
        this.trainer.venue_service_ids.map((service, index) => {
          url += `&venue_service_ids[${index}]=${service}`;
        });
      }
      this.$http
          .get(`${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.trainerDependencies = response.data.data;
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    trainerVenueServices() {
      if (
          !this.trainer.venue_service_ids ||
          this.trainer.venue_service_ids.length == 0
      )
        return [];
      let services = this.venueServices.filter((item) =>
          this.trainer.venue_service_ids.includes(item.venue_service_id)
      );
      return services;
    },


    getMinBookingTimes(index) {
      let duration = [];
      let venueServiceId = this.trainer.training_services[index]
          .venue_service_id;
      const venueService = this.venueServices.find(
          (item) => item.venue_service_id == venueServiceId
      );
      const timeIncrement = venueService ? venueService.time_increment : null;
      if (timeIncrement) {
        let hour = 0;
        let index = 0;
        while (hour < 10) {
          index = index + 1;
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min =
              time / 60 - hour > 0 ? Math.round((time / 60 - hour) * 60) : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${min} Min` : "";
          duration.push({ value: time, text: text });
        }
      }
      return duration;
    },


    visibleTrainers() {
      if (this.trainer.id) {
        return this.trainerDependencies.filter(
            (item) => item.id != this.trainer.id
        );
      }
      return this.trainerDependencies;
    },

    calculateTaxVariation(amount, training_service, training_package, type) {
      let taxTypeId = training_service.tax_type_id;
      let taxPercentage = 0;
      if (taxTypeId) {
        taxPercentage = this.taxTypes.find((item) => item.value == taxTypeId)
            .percentage;
      }
      const priceData = this.getTaxVariation(type, taxPercentage, amount);
      training_package.price = priceData.price;
      training_package.pre_tax_price = priceData.price.toFixed(2);
      training_package.total_price = priceData.total_price;
      this.$forceUpdate();
    },

    taxChange(training_service) {
      training_service.training_packages.forEach((training_package) => {
        if (training_package.price) {
          this.calculateTaxVariation(
              training_package.price,
              training_service,
              training_package,
              "pre"
          );
        } else if (training_package.total_price) {
          this.calculateTaxVariation(
              training_package.total_price,
              training_service,
              training_package,
              "post"
          );
        }
      });
    },
    setCardData(data) {
      if (!data.customer_id) {
        this.$set(this.trainer, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.trainer, "name", data.first_name);
      }

      if (!data.customer_id && this.trainer.name != data.name) {
        this.$set(this.trainer, "mobile", null);
        this.trainer.search = null;
        this.trainer.nameSearch = null;
        this.$set(this.trainer, "is_public", 1);
        this.$set(this.trainer, "email", null);
        this.$set(this.trainer, "gender", null);
        this.$set(this.trainer, "name", null);
        this.$set(this.trainer, "customer_id", null);
        this.$set(this.trainer, "first_name", null);
        this.$set(this.trainer, "image_path", null);
        this.$set(this.trainer, "dob", null);
        this.$set(this.trainer, "country_id", null);
        this.$set(this.trainer, "last_name", null);
        this.$set(this.trainer, "opt_marketing", false);
        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.trainer, "mobile", data.mobile);
      }
      if (data.email) this.$set(this.trainer, "email", data.email);
      if (data.country_id) {
        this.$set(this.trainer, "country_id", data.country_id);
      } else {
        this.$set(this.trainer, "country_id", null);
      }
      if (data.id_proof_type_id) {
        this.$set(this.trainer, "id_proof_type_id", data.id_proof_type_id);
      }

      if (data.id_proof_number) {
        this.$set(this.trainer, "id_proof_number", data.id_proof_number);
      }

      if (data.gender) {
        this.$set(this.trainer, "gender", data.gender);
      } else {
        this.$set(this.trainer, "gender", null);
      }
      if (data.dob) {
        this.$set(this.trainer, "dob", data.dob);
      } else {
        this.$set(this.trainer, "dob", null);
      }

      if (data.image) {
        this.$set(this.trainer, "image", data.image);
      }

      if (data.name) this.$set(this.trainer, "name", data.name);
      if (data.last_name) {
        this.$set(this.trainer, "last_name", data.last_name);
      } else {
        this.$set(this.trainer, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.trainer, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.trainer, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.trainer, "image_path", data.image_path);
      } else {
        this.$set(this.trainer, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.trainer, "opt_marketing", true);
        } else {
          this.$set(this.trainer, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },
  },
  data(){
   return {
     valid:true,
     percentageRules: [
       (value) => {
         if (value && !/^\d+(\.\d+)?%?$/.test(value)) {
           return "Invalid percentage format";
         }
         return true;
       },
     ],
     roles:[],
     trainerDependencies:[],
     packageTypes: [
       { type: "I", name: "Individual" },
       { type: "C", name: "Couple" },
       { type: "G", name: "Group" },
     ],

     confirmModel: {
       id: null,
       title: null,
       description: null,
     },
     index: 0,
   }
  }
}
</script>