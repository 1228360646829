<script>
import TrainerIcon from "@/assets/images/trainers/trainers_icon.svg";
import LogsIcon from "@/assets/images/trainers/logs_icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ConfigIcon from "@/assets/images/trainers/cog_icon.svg";
import AddIcon from "@/assets/images/misc/plus-icon.svg";

export default {
  name: "TrainerTopTabs",
  components: {AddIcon, ConfigIcon, SvgIcon, LogsIcon, TrainerIcon},
  props: {
    venueServices: {type: Array, default: () => []},
    venueServiceIds: {type: Array, default: () => []},
    changeVenueService: {
      type: Function, default: () => {
      }
    },
    addTrainer: {type: Boolean, default: false},
  },
  computed: {
    filterVenueServiceIds: {
      get() {
        return this.$props.venueServiceIds;
      },
      set(value) {
        this.$emit("update:venueServiceIds", value);
      },
    },
    tabs() {
      return [
        {
          name: "Trainers",
          icon: TrainerIcon,
          path: ["/trainers"],
          permission: true
        },
        {
          name: "Sales",
          icon: LogsIcon,
          path: ["/trainer-sales"],
          permission: this.checkWritePermission(this.$modules.trainers.sales.slug)
        },
        {
          name: "Configuration",
          icon: ConfigIcon,
          path: ["/trainers/configuration"],
          permission: this.checkWritePermission(this.$modules.trainers.dashboard.slug)
        },
      ]
    }
  },
  methods: {
    gotoPage(route) {
      this.$router.push(route);
    },
    toggleSelect() {
      this.$nextTick(() => {
        if (this.venueServices.length == this.filterVenueServiceIds.length) {
          this.filterVenueServiceIds = [];
        } else {
          this.filterVenueServiceIds = this.venueServices.map(
              (item) => item.venue_service_id
          );
        }
      });
      setTimeout(() => {
        this.$emit("changeVenueService");
      });
    },
    getIcon() {
      if (this.venueServiceIds.length == 0) return "mdi-checkbox-blank-outline";
      if (this.venueServices.length == this.venueServiceIds.length)
        return "mdi-close-box";
      return "mdi-minus-box";
    },
  }
}
</script>

<template>
  <v-row dense>
    <v-col cols="12" lg="12" xl="12">
      <div class="d-flex justify-space-between ">
        <div class="d-flex bordered qp-tab-nav">
          <div class="nv_item">
            <v-autocomplete
                v-model="filterVenueServiceIds"
                :disabled="venueServices.length === 0"
                :height="46"
                :items="venueServices"
                background-color="rgba(79, 174, 175, 0.1)"
                class="q-autocomplete shadow-0 nv_item_select q-tab-autocomplete"
                color="#4FAEAF"
                dense
                hide-details
                item-color="#4FAEAF"
                item-text="name"
                item-value="venue_service_id"
                multiple
                outlined
                placeholder="Services"
                style="width: 160px;"
                @change="$emit('changeVenueService')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleSelect">
                  <v-list-item-action>
                    <v-icon
                        :color="venueServiceIds.length > 0 ? 'indigo darken-4' : ''"
                    >{{ getIcon() }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>All</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template
                  v-if="venueServiceIds.length == venueServices.length"
                  v-slot:selection="{ index }"
              >
                <span v-if="index == 0" class="text-elepsis text-neon">All Services</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index == 0" class="text-elepsis text-neon">{{ item.name }}</span>
                <span v-if="index == 2" class="text-elepsis text-neon">, ...</span>
              </template>
            </v-autocomplete>
          </div>
          <div class="d-flex align-center p-2 tabs">
            <template v-for="(tab,index) in tabs">
              <div
                  v-if="tab.permission"
                  v-bind:key="tab.name"
                  :class="{'tab':index < tabs.length-1}"
                  class="nv_item d-flex pointer"
                  @click="gotoPage(tab.path[0])"
              >
                <SvgIcon
                    :class="{
                    'qp-tab-nav-is-active':tab.path.includes($route.path),
                    'qp-tab-nav':!tab.path.includes($route.path)
                  }"
                    :text="tab.name"
                    class="text-thin-gray"
                >
                  <template v-slot:icon>
                    <component :is="tab.icon" :component="tab.icon"/>
                  </template>
                </SvgIcon>
              </div>
            </template>
          </div>
        </div>
        <div v-if="addTrainer" class="d-flex gap-x-2">
          <v-btn
              v-if="checkWritePermission($modules.trainers.dashboard.slug)"
              class="white--text blue-color ml-1"
              color=" darken-1"
              height="48"
              text
              @click="$emit('addTrainer')"
          >
            <AddIcon/>
            <span class="ml-1">Add Trainer</span>
          </v-btn>
        </div>

      </div>
    </v-col>
  </v-row>

</template>

<style scoped>

</style>