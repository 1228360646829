const trainer = {
  methods: {
    trainerDetails(id, viewPage , openInModal=false) {

      if (!id) return;
      this.$http
        .get(`venues/trainers/${id}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainer = response.data.data;
            let productIds = [];
            this.trainer.training_services.map((service) => {
              if (service.training_packages.length > 0) {
                service.tax_type_id = service.training_packages[0].tax_type_id;
                service.training_packages.forEach((pkg) => {
                  pkg.venue_service_id = service.venue_service_id;
                });
                productIds.push(
                  ...service.training_packages.map((item) => item.product_id)
                );
              }
              if (viewPage != null) {
                this.params.product_ids = productIds;
              }
            });

            this.trainer.venue_service_ids = this.trainer.venue_services.map(
              (item) => item.venue_service_id
            );
            this.trainer.trainer_ids = this.trainer.trainer_dependencies.map(
              (item) => item.child_trainer_id
            );
            this.trainer.deleted_packages = [];
            this.trainer.deleted_services = [];
            this.trainer.status_id = this.trainer.status_id.toString();
            this.trainer.image_path = this.trainer.profile_image
              ? this.trainer.profile_image
              : null;
            if(openInModal){
              this.addTrainerDialog = true;
              this.editFlag = true;
            }
            if (viewPage != null) {
              this.getLevels();
            } else {
              this.getTrainerDependencies();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setPackagePeople(serviceIndex, pkgIndex) {
      let pkgType = this.trainer.training_services[serviceIndex]
        .training_packages[pkgIndex].package_type;
      if (pkgType == "I") {
        this.trainer.training_services[serviceIndex].training_packages[
          pkgIndex
        ].people = 1;
      } else if (pkgType == "C") {
        this.trainer.training_services[serviceIndex].training_packages[
          pkgIndex
        ].people = 2;
      } else if (pkgType == "G") {
        this.trainer.training_services[serviceIndex].training_packages[
          pkgIndex
        ].people = null;
      }
    },
    toggleTrainerStatus(id, trainerCustomerFlag) {
      this.$http
        .put(`venues/trainers/${id}/status`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (trainerCustomerFlag) {
              this.$router.push({
                name: "Trainers",
              });
            } else {
              this.loadTrainers();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addOrEditTrainer(e, flag) {
      if (e != null) {
        e.preventDefault();
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
      }

      let formData = new FormData();
      this.showLoader();
      if (
        this.trainer.profile_image != null &&
        typeof this.trainer.profile_image != "string"
      )
        formData.append("profile_image", this.trainer.profile_image);
      formData.append("is_public", this.trainer.is_public);
      formData.append("first_name", this.trainer.first_name);
      formData.append("last_name", this.trainer.last_name);
      formData.append("designation", this.trainer.designation);
      formData.append("email", this.trainer.email);
      formData.append("mobile", this.trainer.mobile);
      if( this.trainer.profile_url!=undefined){
        formData.append("profile_url", btoa(this.sanitizeHTML(this.trainer.profile_url)));
      }
      formData.append("username", this.trainer.username);
      formData.append(
        "commission_percentage",
        this.trainer.commission_percentage
      );
      if (this.trainer.description) {
        formData.append("description", this.trainer.description);
      } else {
        formData.append("description", null);
      }
      let name =
        this.trainer.first_name.trim() + " " + this.trainer.last_name.trim();
      formData.append("name", name);
      if (this.trainer.password)
        formData.append("password", this.trainer.password);
      if (this.trainer.role_id)
        formData.append("role_id", this.trainer.role_id);
      if (this.trainer.venue_service_ids.length)
        this.trainer.venue_service_ids.forEach((venueServiceId, index) => {
          formData.append(`venue_service_ids[${index}]`, venueServiceId);
        });
      if (this.trainer.trainer_ids && this.trainer.trainer_ids.length)
        this.trainer.trainer_ids.forEach((trainerId, index) => {
          formData.append(`trainer_ids[${index}]`, trainerId);
        });
      if (this.trainer.venue_ids && this.trainer.venue_ids.length){
        this.trainer.venue_ids.forEach((venueId, index) => {
          formData.append(`venue_ids[${index}]`, venueId);
        });
      }else{
        formData.append(`venue_ids`, null);
      }
      formData.append("status_id", this.trainer.status_id);
      if (
        this.trainer.training_services.length > 0 &&
        this.trainer.status_id == 1
      ) {
        this.trainer.training_services.forEach((trainerService, si) => {
          if (trainerService.venue_service_id && trainerService.tax_type_id) {
            formData.append(
              `training_services[${si}][venue_service_id]`,
              trainerService.venue_service_id
            );
            if (trainerService.id != null) {
              formData.append(
                `training_services[${si}][id]`,
                trainerService.id
              );
            }
            trainerService.training_packages.forEach((trainerPackage, pi) => {
              formData.append(
                `training_services[${si}][training_packages][${pi}][venue_service_id]`,
                trainerService.venue_service_id
              );
              if (trainerPackage.duration) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][duration]`,
                  trainerPackage.duration
                );
              }
              formData.append(
                `training_services[${si}][training_packages][${pi}][name]`,
                trainerPackage.name
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][sessions]`,
                trainerPackage.sessions
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][validity_days]`,
                trainerPackage.validity_days
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][price]`,
                trainerPackage.price
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][package_type]`,
                trainerPackage.package_type
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][people]`,
                trainerPackage.people
              );
              formData.append(
                `training_services[${si}][training_packages][${pi}][tax_type_id]`,
                trainerService.tax_type_id
              );
              if (trainerPackage.id) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][id]`,
                  trainerPackage.id
                );
              }
              if (trainerPackage.project_no) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][project_no]`,
                  trainerPackage.project_no
                );
              }
              if (trainerPackage.task_name) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][task_name]`,
                  trainerPackage.task_name
                );
              }
              if (trainerPackage.gl_code) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][gl_code]`,
                  trainerPackage.gl_code
                );
              }
              if (trainerPackage.transaction_type) {
                formData.append(
                  `training_services[${si}][training_packages][${pi}][transaction_type]`,
                  trainerPackage.transaction_type
                );
              }
            });
          }
        });
      }

      if (this.trainer.deleted_packages.length > 0) {
        this.trainer.deleted_packages.forEach((item, index) => {
          formData.append(`deleted_packages[${index}]`, item);
        });
      }
      if (this.trainer.deleted_services.length > 0) {
        this.trainer.deleted_services.forEach((item, index) => {
          formData.append(`deleted_services[${index}]`, item);
        });
      }

      this.$http
        .post(
          "venues/trainers" +
            (this.trainer.id != null ? `/${this.trainer.id}` : ""),
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message = response.data.message;
            this.showSuccess(message);
            this.hideLoader();
            if(flag){
              if (flag === 'EditCustomerModal') {
                  this.$emit('cancelPersonalTraining',true)
              }else{
                this.trainerDetails(this.trainer.id, true);
                this.trainerPackageDialog = false;
              }
            }else {
              this.$emit('cancelPersonalTraining',true)
              // this.loadTrainers();
              // this.addTrainerDialog = false;
            }

          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    warningPackageRemove(serviceIndex, packageIndex) {
      let pkg = this.trainer.training_services[serviceIndex].training_packages[
        packageIndex
      ];
      if (pkg.name == null && pkg.sessions == null && pkg.price == null) {
        this.trainer.training_services[serviceIndex].training_packages.splice(
          packageIndex,
          1
        );
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: packageIndex,
          title: "Do you want to delete this package?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "package",
          data: {
            serviceIndex: serviceIndex,
            packageIndex: packageIndex,
          },
        };
      }
    },
    confirmDelete(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${
          data.status_id == 1 ? "delete" : "reactivate"
        } this trainer?`,
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "trainer",
        data: {
          flag: data.flag ? true : false,
        },
      };
    },
    deleteTrainerService(index, internal) {
      console.log(this.$event)
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this service?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "service",
        data: {
          event: this.$event,
          flag: internal != null ? true : false,
        },
      };
    },
    confirmActions(data) {
      if (data.type == "package") {
        let serviceIndex = data.data.serviceIndex;
        let packageIndex = data.data.packageIndex;

        if (
          this.trainer.training_services[serviceIndex].training_packages[
            packageIndex
          ].id != null
        ) {
          this.trainer.deleted_packages.push(
            this.trainer.training_services[serviceIndex].training_packages[
              packageIndex
            ].id
          );
        }
        this.trainer.training_services[serviceIndex].training_packages.splice(
          packageIndex,
          1
        );
        this.$forceUpdate();
      } else if (data.type == "service") {
        let index = data.id;

        if (this.trainer.training_services[index].id != null) {
          this.trainer.deleted_services.push(
            this.trainer.training_services[index].id
          );
        }
        this.trainer.training_services.splice(index, 1);
      } else if (data.type == "trainer") {
        this.toggleTrainerStatus(data.id, data.data.flag);
      }
      if (data.data.flag && data.type != "trainer") {
        this.addOrEditTrainer(data.data.event, true);
      }
      this.confirmModel.id = null;
    },
    addTrainerPackage(serviceIndex) {
      this.trainer.training_services[serviceIndex].training_packages.push({
        id: null,
        name: null,
        sessions: null,
        validity_days: null,
        price: null,
      });
      this.$forceUpdate();
    },
    addTrainerService() {
      this.trainer.training_services.push({
        venue_service_id: null,
        training_packages: [
          {
            name: null,
            sessions: null,
            validity_days: null,
            price: null,
          },
        ],
      });
      this.index = this.trainer.training_services.length - 1;
      this.trainerPackageDialog = true;
    },
    getLevels() {
      let url = this.trainer.venue_service_ids.map(
          (id, index) => `&venue_service_ids[${index}]=${id}`
      );
      this.$http
          .get(`venues/general/color-codes/trainer?q=1&${url}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.length) {
                this.levels = [];
                this.levels.push({ divider: true });
                this.levels.push({ header: "General" });
                this.levels.push({ divider: true });
                this.levels.push({ id: "All", name: "All" });
                this.levels.push({
                  id: -1,
                  name: "Not Applied",
                  color_code: "#555",
                });
                let service = data[0].service;
                this.levels.push({ divider: true });
                this.levels.push({ header: service });
                this.levels.push({ divider: true });
                data.forEach((level) => {
                  if (level.service != service) {
                    this.levels.push({ divider: true });
                    this.levels.push({ header: level.service });
                    this.levels.push({ divider: true });
                    service = level.service;
                  }
                  this.levels.push(level);
                });
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
  },
};

export default trainer;
