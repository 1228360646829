<template>
  <v-img
      :src="image ? s3BucketURL + image : images[defaultImage]"
      :width="width ? width : 'auto'"
      :contain="!contain"
      aspect-ratio="1"
      v-bind="$attrs"
      style="aspect-ratio: 1/1"
  >
    <slot name="overlay"></slot>
  </v-img>
</template>

<script>
import images from "@/utils/images";
export default {
  props: {
    image: { type: String, default: null },
    height: { type: Number, default: 120 },
    width: { type: Number, default: null },
    defaultImage: { type: String, default: "default" },
    contain: { type: Boolean, default: true },
  },
  data() {
    return {
      images: images,
    };
  },
};
</script>

<style>
</style>