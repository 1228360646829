<template>
  <v-dialog v-model="trainingPackageDialog" scrollable width="50%">
    <v-form ref="form">
      <v-card>
        <v-card-title class="headline">Training Packages</v-card-title>
        <v-card-text class="form_bg">
          <v-container>
            <v-card
              class="pt-3 pl-2 pr-2 mb-3"
              :style="cardStyle"
              elevation="0"
              v-for="(trainingService, index) in trainingServices"
              :key="index"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    v-if="trainingServices.length > 1"
                    fab
                    dark
                    x-small
                    absolute
                    top
                    right
                    color="dark-grey"
                    style="margin-right: -20px"
                    @click="removeTrainingService(index)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                Delete
              </v-tooltip>
              <v-row>
                <v-col md="6">
                  <v-autocomplete
                    v-model="trainingService.venue_service_id"
                    :items="venueServices"
                    clearable
                    label="Select Service"
                    item-text="name"
                    item-value="venue_service_id"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Service is required']"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col md="6">
                  <v-autocomplete
                    v-model="trainingService.tax_type_id"
                    :items="taxTypes"
                    clearable
                    label="Tax type"
                    item-text="text"
                    item-value="value"
                    outlined
                    background-color="#fff"
                    required
                    :rules="[(v) => !!v || 'Tax type is required']"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-card
                v-for="(
                  trainerPackage, pIndex
                ) in trainingService.training_packages"
                :key="`${index}_${pIndex}`"
                outlined
                class="mb-6"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="trainingService.training_packages.length > 1"
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      class="mt-1"
                      absolute
                      right
                      top
                      @click="removeTrainingPackage(index, pIndex)"
                      fab
                      x-small
                      dark
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
                <v-container>
                  <v-row>
                    <v-col md="4">
                      <v-text-field
                        label="Name*"
                        required
                        v-model="trainerPackage.name"
                        :rules="[(v) => !!v || 'Package name is required']"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        label="Duration"
                        required
                        v-model="trainerPackage.duration"
                        hint="Session duration (default 1 hr)"
                        :items="[
                          { text: '15 min', value: 15 },
                          { text: '30 min', value: 30 },
                          { text: '45 min', value: 45 },
                          { text: '1 hr', value: 60 },
                        ]"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                      ></v-select>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        label="Sessions*"
                        required
                        v-model="trainerPackage.sessions"
                        :rules="[
                          (v) => !!v || 'Sessions is required',
                          (v) => !isNaN(v) || 'Sessions must be Number',
                        ]"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-select
                        label="Type*"
                        required
                        v-model="trainerPackage.package_type"
                        outlined
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        :items="packageTypes"
                        @change="setPackageTypePeople(index, pIndex)"
                        item-text="name"
                        item-value="type"
                        :rules="[(v) => !!v || 'Type is required']"
                      ></v-select>
                    </v-col>
                    <v-col md="4" v-if="trainerPackage.package_type == 'G'">
                      <v-text-field
                        label="People*"
                        required
                        v-model="trainerPackage.people"
                        :rules="[
                          (v) => !!v || 'No persons is required',
                          (v) => !isNaN(v) || 'Price must be Number',
                        ]"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                    <v-col md="4">
                      <v-text-field
                        label="Price*"
                        required
                        v-model="trainerPackage.price"
                        :rules="[
                          (v) => !!v || 'Price is required',
                          (v) => !isNaN(v) || 'Price must be Number',
                        ]"
                        :prefix="currencyCode"
                        outlined
                        background-color="#fff"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <div class="add_btn pb-4" style="margin-top: -30px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      color="teal"
                      fab
                      x-small
                      dark
                      @click="addPackage(index)"
                    >
                      <v-icon small>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add
                </v-tooltip>
              </div>
            </v-card>
          </v-container>
          <div class="add_btn">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="teal-color"
                  small
                  dark
                  @click="addService"
                >
                  Service
                  <v-icon class="ml-2" small>mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              Add
            </v-tooltip>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="close"
            >Close</v-btn
          >

          <v-btn
            class="ma-2 white--text teal-color"
            @click="addOrEditTrainerServices($event)"
            >Update</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-dialog>
</template>

<script>
export default {
  props: {
    trainingPackageDialog: { type: Boolean, default: false },
  },
  data() {
    return {
      trainingServices: [],
      deleted_services: [],
      deleted_packages: [],
      packageTypes: [
        { type: "I", name: "Individual" },
        { type: "C", name: "Couple" },
        { type: "G", name: "Group" },
      ],
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    taxTypes() {
      return this.$store.getters.getTaxTypes.data;
    },
  },
  watch: {
    trainingPackageDialog: {
      immediate: true,
      handler(val) {
        if (val) this.loadTrainingServices();
      },
    },
  },
  methods: {
    loadTrainingServices() {
      this.showLoader("Loading");
      this.$http
        .get(`venues/trainers/services`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.length) {
              this.trainingServices = data.map((item) => {
                let service = item;
                service.tax_type_id = service.training_packages[0].tax_type_id;
                return service;
              });
            } else {
              this.trainingServices = [
                {
                  venue_service_id: null,
                  training_packages: [
                    {
                      name: null,
                      session: null,
                      package_type: null,
                      price: null,
                    },
                  ],
                },
              ];
            }
            this.deleted_services = [];
            this.deleted_packages = [];
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    setPackageTypePeople(serviceIndex, pkgIndex) {
      let pkgType =
        this.trainingServices[serviceIndex].training_packages[pkgIndex]
          .package_type;
      if (pkgType == "I") {
        this.trainingServices[serviceIndex].training_packages[
          pkgIndex
        ].people = 1;
      } else if (pkgType == "C") {
        this.trainingServices[serviceIndex].training_packages[
          pkgIndex
        ].people = 2;
      } else if (pkgType == "G") {
        this.trainingServices[serviceIndex].training_packages[pkgIndex].people =
          null;
      }
    },
    removeTrainingPackage(serviceIndex, packageIndex) {
      let pkg =
        this.trainingServices[serviceIndex].training_packages[packageIndex];
      if (pkg.name == null && pkg.sessions == null && pkg.price == null) {
        this.trainingServices[serviceIndex].training_packages.splice(
          packageIndex,
          1
        );
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: packageIndex,
          title: "Do you want to delete this package?",
          description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "package",
          data: {
            serviceIndex: serviceIndex,
            packageIndex: packageIndex,
          },
        };
      }
    },
    removeTrainingService(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to delete this service?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "service",
      };
    },
    confirmActions(data) {
      if (data.type == "package") {
        let serviceIndex = data.data.serviceIndex;
        let packageIndex = data.data.packageIndex;

        if (
          this.trainingServices[serviceIndex].training_packages[packageIndex]
            .id != null
        ) {
          this.deleted_packages.push(
            this.trainingServices[serviceIndex].training_packages[packageIndex]
              .id
          );
        }
        this.trainingServices[serviceIndex].training_packages.splice(
          packageIndex,
          1
        );
        this.$forceUpdate();
      } else if (data.type == "service") {
        let index = data.id;
        if (this.trainingServices[index].id != null) {
          this.deleted_services.push(this.trainingServices[index].id);
        }
        this.trainingServices.splice(index, 1);
      }
      this.confirmModel.id = null;
    },
    addPackage(index) {
      this.trainingServices[index].training_packages.push({
        name: null,
        session: null,
        package_type: null,
        price: null,
      });
    },
    addService() {
      this.trainingServices.push({
        venue_service_id: null,
        tax_type_id: null,
        training_packages: [
          {
            name: null,
            session: null,
            package_type: null,
            price: null,
          },
        ],
      });
    },
    close() {
      this.$emit("close");
    },
    addOrEditTrainerServices() {
      let data = this.trainingServices.map((service) => {
        service.training_packages.map((pkg) => {
          let pckge = pkg;
          pckge.tax_type_id = service.tax_type_id;
          return pckge;
        });
        return service;
      });
      let postData = {
        training_services: data,
      };
      if (this.deleted_services.length) {
        postData.deleted_services = this.deleted_services;
      }
      if (this.deleted_packages.length) {
        postData.deleted_packages = this.deleted_packages;
      }
      this.$http
        .post(`venues/trainers/services`, postData)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Training packages updated successfully");
            this.close();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style>
</style>