const customerTraining = {
  data() {
    return {
      packages: [],
      trainers: [],
      levels: [],
    };
  },
  methods: {
    getLevels(pkg) {
      this.data.products.push({
        product_id: pkg.product_id,
        product_type_id: pkg.product_type_id,
        price: pkg.price,
        name: pkg.name,
        tax: pkg.tax_amount,
        quantity: 1,
      });

      this.$http
        .get(
          `venues/general/color-codes/trainer?venue_service_id=${pkg.venue_service_id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.levels = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    async getTrainers() {
      let url = "";
      if (this.venue_service_id) {
        url = `?venue_service_ids[0]=${this.venue_service_id}`;
      }
      await this.$http
        .get(`venues/trainers/short` + url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainers = response.data.data;

            if (!this.editFlag && this.trainerId != null) {
              this.registerForm.trainer_ids = [this.trainerId];
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    async getPackages() {
      await this.$http
        .get(
          `venues/trainers/packages${
            this.trainerId != null ? "?trainer_id=" + this.trainerId : ""
          }`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.packages = [];
            if (data.length) {
              let service = data[0].service;
              this.packages.push({ header: service });
              this.packages.push({ divider: true });
              data.forEach((pkg) => {
                if (pkg.service != service) {
                  this.packages.push({ divider: true });
                  this.packages.push({ header: pkg.service });
                  this.packages.push({ divider: true });
                  service = pkg.service;
                }
                this.packages.push(pkg);
              });
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};

export default customerTraining;
